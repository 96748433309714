<template>
  <div>
    <v-card class="pa-6">
      <v-row>
        <v-col cols="6">
          <pre>{{ model.describe }}</pre>
          <pre><b>{{ model.checkListFiles.length }} ไฟล์ ได้แก่</b></pre><br>
          <ValidationObserver
            ref="observer"
            v-slot="{ valid }"
          >
            <div
              v-for="(checklist, index) in model.checkListFiles"
              :key="index"
            >
              <render-input
                :key="index"
                :input="
                  Object.assign({}, fieldConfig.checklist, {
                    text: checklist.text,
                  })
                "
                v-model="model.checkListFiles[index].checked"
                class="checklist-file"
                :disabled="!model.checkListFiles[index].allowEdit"
                v-if="conditionShowCheckList"
              ></render-input>

              <p v-else>{{index+1}}. {{ checklist.text }}</p>
              <v-row
                class="ml-8 mt-2 mb-n8"
                v-if="model.paymentPartId == PaymentPart.namKamBangKub &&  checklist.text.includes('ใบเสร็จ')"
              >
                <v-col cols="4">
                  <render-input
                    v-model="model.checkListFiles[index].pipaksaReceiptAmount"
                    :input="(conditionShowCheckList && checklist.allowEdit)?fieldConfig.pipaksaReceiptAmount: Object.assign({}, fieldConfig.pipaksaReceiptAmount, {
                               disabled: true,
                      })"
                  ></render-input>

                </v-col>
              </v-row>
            </div>
            <v-btn
              v-if="conditionShowCheckList && model.checkListFiles.some(x=>x.allowEdit)"
              color="primary"
              class="mt-8"
              :disabled="!valid"
              @click="updateChecklist()"
            >Submit</v-btn>
          </ValidationObserver>
          <!-- 
            <span v-else>
              <p
                v-for="(checklist, index) in model.checkListFiles"
                :key="index"
              >{{index+1}}. {{ checklist.text }}</p>
            </span> -->

          <v-row class="mt-5">
            <v-col v-if="model.paymentPartId">
              <upload-attachment-section
                class="mb-2"
                :getUrl="urlGetAttachment"
                :uploadUrl="urlUploadAttachment"
                :appendForm="Object.assign({}, { caseId: caseId })"
                :showUploadButton="true"
                :showRemove="true"
              ></upload-attachment-section>
            </v-col>
          </v-row>
        </v-col>

      </v-row>

      <v-btn
        color="primary"
        class="mt-8"
        @click="lawyerSubmit()"
        v-if="model.showSubmitButton"
      >ยืนยันการแนบไฟล์</v-btn>

      <div v-if="model.showConsiderZone">
        <v-divider class="my-4"></v-divider>
        <v-row>
          <v-col cols="3">
            <render-input
              :input="fieldConfig.consider"
              v-model="model.consider"
              :disabled="!model.showConsiderButton"
            ></render-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <render-input
              :input="fieldConfig.ncbRejectReason"
              v-model="model.ncbRejectReason"
              v-if="model.consider?.id == 0"
              :disabled="!model.showConsiderButton"
            ></render-input>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          class="mt-8"
          @click="consider()"
          v-if="model.showConsiderButton && model.consider"
        >สรุปผล</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { create } from "@amcharts/amcharts4/core";
import { InputType, PaymentPart, UserRole } from "../../js/constants";
import UploadAttachmentSection from "./../global/upload-attachment-section";

export default {
  components: {
    UploadAttachmentSection,
  },
  data() {
    return {
      caseId: null,
      PaymentPart: PaymentPart,
      // model: {
      //   consider: "",
      //   showPanel: null,
      //   ncbRejectReason: null,
      //   describe: "",
      //   checkListFiles: [

      //   ],
      // },
    };
  },
  created() {
    this.caseId = this.state.id;
    // this.caseId = "fd0af3c2-2ff5-4381-a552-08db75fd9251";
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch("caseinfo/getNCBData");

      // let url = `/case/${this.caseId}/ncb/get`;

      // this.$http.get(url).then((res) => {
      //   this.model = res;
      // });
    },
    updateChecklist() {
      let url = `/case/${this.caseId}/ncb/updateChecklist`;

      this.$http.post(url, _.cloneDeep(this.model)).then((res) => {
        this.showAlertSuccess();
        this.getData();
        this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
      });
    },
    lawyerSubmit() {
      let url = `/case/${this.caseId}/ncb/updateStatus/submit`;

      this.$http.post(url, _.cloneDeep(this.model)).then((res) => {
        this.showAlertSuccess();
        this.getData();
      });
    },
    consider() {
      let url = `/case/${this.caseId}/ncb/updateStatus/consider`;

      this.$http.post(url, _.cloneDeep(this.model)).then((res) => {
        this.showAlertSuccess();
        this.getData();
      });
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    model: {
      get() {
        return this.state.ncbModel;
      },
      set(val) {
        this.state.ncbModel = val;
      },
    },
    urlGetAttachment() {
      let url = `/case/${this.caseId}/payment/attachment/${this.model.paymentPartId}`;
      return url;
    },
    urlUploadAttachment() {
      let url = `/case/${this.caseId}/payment/attachment/${this.model.paymentPartId}`;
      return url;
    },
    conditionShowCheckList() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MANAGEMENT,
        UserRole.BACK_OFFICE,
      ];
      return this.hasPermission(permission);
    },
    fieldConfig() {
      return {
        checklist: {
          name: "checklist",
          text: "",
          type: InputType.checkBox,
        },
        name: {
          name: "name",
          text: "รายละเอียด",
          type: InputType.text,
        },
        consider: {
          name: "consider",
          text: "ผลการพิจารณา",
          type: InputType.dropdown,
          data: {
            promise: Promise.resolve({
              items: [
                { id: true, text: "ข้อมูลครบถ้วน" },
                { id: false, text: "ปฏิเสธ" },
              ],
            }),
          },
          columnClass: "col-md-6",
        },
        ncbRejectReason: {
          name: "ncbRejectReason",
          text: "เหตุผลที่ปฏิเสธ",
          type: InputType.textArea,
          columnClass: "col-md-6",
        },
        pipaksaReceiptAmount: {
          text: "จำนวนเงิน",
          name: "pipaksaReceiptAmount",
          type: InputType.number,
          decimal: 2,
          rule: {
            min_value: 1,
            required: this.conditionShowCheckList,
          },
        },
      };
    },
  },
};
</script>

<style>
</style>